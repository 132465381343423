import React, { useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { authRoutes } from "./navigators/routes/auth";
import {
  ButtonStylesParams,
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import LandingPage from "./screens/landing/LandingPage";

function App() {
  const theme = useMantineTheme();
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  return (
         <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}>
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              colorScheme: colorScheme,
              fontFamily: 'Nunito Sans, sans-serif',
              fontFamilyMonospace: 'Liberation Mono, Courier, monospace',
              headings: { fontFamily: 'Nunito Sans, sans-serif' },
              
              components: {
                Text: {
                  styles: (theme, params: ButtonStylesParams, { variant }) => ({
                    root: {
                      color:
                        theme.colorScheme == "dark" ? theme.white : theme.black,
                        fontWeight: 400
                    },
                    
                  }),
                },
                TextInput: {
                  styles: (theme, params: ButtonStylesParams, { variant }) => ({
                    root: {
                      backgroundColor:
                        theme.colorScheme == "dark" ? theme.black : theme.white,
                      
                    },
                  }),
                },
              },
            }}
          >
                <Routes>
                <Route path={'/'} element={<Navigate replace to={authRoutes.LANDING} />} />
                  <Route path={authRoutes.LANDING} element={<LandingPage />} />
                </Routes>
          </MantineProvider>
        </ColorSchemeProvider>
  );
}

export default App;
