// icons/IconRegistry.ts
import SearchIcon from '@mui/icons-material/Search';
import UserIcon from '@mui/icons-material/Person';
import FourDots from "../../../../../public/staticwebapp.config.json"
import { PaletteOutlined, BuildCircleOutlined, CopyAllOutlined, DarkMode, DarkModeOutlined, Delete, DeleteOutline, DonutLargeOutlined, FileUploadOutlined, FilterCenterFocusOutlined, HomeOutlined, HomeRounded, InfoOutlined, LightMode, LightModeOutlined, LogoutOutlined, MessageOutlined, MoodOutlined, MoreHorizOutlined, SaveOutlined, StopCircle, TuneOutlined, WorkspacesRounded, Psychology, SettingsOutlined, CopyAllRounded, ContentCopyRounded, CropSquareSharp, CancelOutlined, Circle, CircleOutlined, CheckCircle, ArrowBack, RefreshOutlined, BrushOutlined, EditNote, PsychologyOutlined, AttachFileOutlined, InsertDriveFile, AttachFile, Close, Image, Check, QuestionMark, QuestionAnswer, FlagOutlined, ChatBubble, ChatBubbleOutline, ChatBubbleOutlineRounded, ChatBubbleRounded, ListAltOutlined, HistoryEduOutlined, HistoryOutlined, PersonAddAlt1Outlined, ImageOutlined, PictureAsPdfOutlined, ArrowForward, FacebookOutlined, Twitter, Instagram, Menu, FormatListBulleted, PersonAddAltOutlined, AutoAwesome, Token, WhatsApp, PinDrop, FmdGood } from '@mui/icons-material';
import { GoogleIcon } from '../GoogleIcon';
import { EyeIcon, EyeSlashIcon, PencilIcon , HomeIcon} from '@heroicons/react/24/solid';
import { ChatBubbleOvalLeftIcon, HomeIcon as HomeIconOutline, Cog6ToothIcon, FireIcon } from '@heroicons/react/24/outline';

// Exporting Material UI icons as a centralized registry
export const iconRegistry = {
  search: SearchIcon,
  user: UserIcon,
  eye: EyeIcon,
  eyeSlash: EyeSlashIcon,
   list: FormatListBulleted,
   star: AutoAwesome,
   pin: FmdGood,
   windmill: Token,
   whatsapp: WhatsApp,
  image: ImageOutlined,
  doc: PictureAsPdfOutlined,
  recent: HistoryOutlined,
  chat: ChatBubbleOvalLeftIcon,
  home: HomeIconOutline,
  addUser: PersonAddAltOutlined,
  clear: DeleteOutline,
  next: ArrowForward,
  darkMode: DarkModeOutlined,
  lightMode: LightModeOutlined,
  logout: LogoutOutlined,
  fourDots: FilterCenterFocusOutlined,
  info: InfoOutlined,
  upload: FileUploadOutlined,
  edit: EditNote,
  flag: FlagOutlined,
  cross: Close,
  google: GoogleIcon,
  settings: Cog6ToothIcon,
  copy: ContentCopyRounded,
  message: MessageOutlined,
  threeDots: MoreHorizOutlined,
  check: Check,
  knowledgebase: CopyAllOutlined,
  save: SaveOutlined,
  delete: CancelOutlined,
  question: QuestionMark,
  facebook: FacebookOutlined,
  twitter: Twitter,
  instagram: Instagram,
  qa: QuestionAnswer,
  pen: EditNote,
  brain: Psychology,
  menu: Menu,
  attach: AttachFile,
  file: InsertDriveFile,
  trash: DeleteOutline,
  refresh: RefreshOutlined,
  bullet: Circle,
  success: CheckCircle,
  back: ArrowBack,
  stop: StopCircle,
  paletteOutlined: PaletteOutlined,
  settings2: SettingsOutlined
};

// TypeScript type for the registry keys
export type IconName = keyof typeof iconRegistry;
